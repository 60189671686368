<template>
  <div style="height: 100%;">
    <v-container fluid style="height: 100%;">
      <v-row style="height: 90%">
        <v-col cols="12" style="height: 50px; margin: 0; padding-top: 0;">
          <v-select
            :items="documentTypes"
            v-model="showDocumentTypes"
            label="Document types"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
        >
          <v-card
            v-if="documents.length > 0"
            class="mx-auto"
            max-width="100%"
            tile
          >
            <v-list two-line
                    v-if="documents"
            >
              <template v-for="(document, index) in documents">
                <v-list-item
                  :key="document.file_name"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ document.partner || document.file_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption">
                      {{ documentTypesMap[document.document_type] }} |
                      {{ document.created_at | formatDateFilter }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption">
                      {{ statusMap[document.status] || 'Accepted' }}
                      <span v-if="document.total_amount">
                         | {{ document.total_amount }} {{ document.currency }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn
                      icon
                      color="primary"
                      @click="openPreview(document.file)"
                    >
                      <v-icon x-large>{{ filePreviewIcon }}</v-icon>
                    </v-btn>

                  </v-list-item-icon>
                </v-list-item>
                <v-divider
                  v-if="index + 1 < documents.length"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </v-card>
          <div class="text-center" v-else>No documents to show.</div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-pagination v-model="page" :length="pageCount" @input="onPageChange"></v-pagination>
      </v-row>
    </v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="primary" height="80px">
            <div class="d-flex" style="flex-direction: column">
              <div class="">
                Page {{ pdfPage }}/{{ pageNumbers.length }}
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="dialog = false">Close</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-progress-linear
            v-if="pdfLoading"
            indeterminate
            color="primary lighten-2"
          ></v-progress-linear>
          <pdf
            v-touch="{
              left: () => incrementPdfPage(),
              right: () => decrementPdfPage(),
              up: () => {},
              down: () => {}
            }"
            :src="previewFileUrl"
            :page="pdfPage"
            @num-pages="setNumPages($event)"
            @link-clicked="pdfPage = $event"
            @loaded="pdfLoading = false"
          ></pdf>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { mdiFileEyeOutline } from '@mdi/js';
import axiosInstance from '../plugins/axios';

export default {
  name: 'History',
  components: { pdf },
  filters: {
    formatDateFilter(date) {
      return new Date(date).toLocaleDateString('de-DE').replace(',', '');
    },
  },
  data() {
    return {
      filePreviewIcon: mdiFileEyeOutline,
      page: 1,
      pageCount: 1,
      documents: [],
      dialog: false,
      previewFileUrl: null,
      numPages: 0,
      pdfPage: 1,
      pageNumbers: [1],
      pdfLoading: true,
      documentTypes: [
        { text: 'All', value: 'INV,IRN,OST' },
        { text: 'Received invoices', value: 'INV' },
        { text: 'Issued invoices', value: 'IRN' },
        { text: 'Other', value: 'OST' },
      ],
      documentTypesMap: {
        INV: 'Received invoice',
        IRN: 'Issued invoice',
        OST: 'Other'
      },
      statusMap: {
        EX: 'Processed',
      },
      showDocumentTypes: 'INV,IRN,OST',
      currentPage: 1
    };
  },
  mounted() {
    this.fetchDocuments(1);
  },
  methods: {
    async fetchDocuments(page) {
      const { data } = await axiosInstance.get('uploaded-documents/', { params: { limit: 5, page, document_types: this.showDocumentTypes } });
      this.documents = data.results
      this.page = data.page
      this.pageCount = Math.ceil(data.count / 5)
    },
    onPageChange(newPage) {
      this.fetchDocuments(newPage);
      this.currentPage = newPage;
    },
    openPreview(fileUrl) {
      this.dialog = true;
      this.previewFileUrl = fileUrl;
    },
    setNumPages(numPages) {
      this.numPages = numPages;
      const pageNumbers = [];
      for (let pageNumber = 1; pageNumber <= numPages; pageNumber += 1) {
        pageNumbers.push(pageNumber)
      }
      this.pageNumbers = pageNumbers;
    },
    incrementPdfPage() {
      if (this.pdfPage < this.pageNumbers.length) {
        this.pdfPage += 1;
      }
    },
    decrementPdfPage() {
      if (this.pdfPage > 1) {
        this.pdfPage -= 1;
      }
    }
  },
  watch: {
    showDocumentTypes(newValue) {
      this.showDocumentTypes = newValue;
      this.fetchDocuments(this.currentPage);
    }
  }
};
</script>

<style>
.container.fill-height > .row {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: unset;
}
</style>
